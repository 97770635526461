import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg">
          <div className="onas">
            <div className="wrapper onas">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/o-neutrogenie/kim-jestesmy/"
                      className="section"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/site/bad-piekna/" className="link">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a href="/site/see-whats-possible/" className="link">
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>
                  O marce
                  <br /> NEUTROGENA<sup>®</sup>
                </h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/">
                    O Neutrogenie
                  </a>{" "}
                  <span>&gt;</span>
                  Kim jesteśmy
                </div>
              </div>
            </div>
          </div>
          <div className="submenu">
            <div className="wrapper">
              <a href="/site/o-neutrogenie/kim-jestesmy/" className="current">
                Kim jesteśmy
              </a>
              <a href="/site/o-neutrogenie/historia/" className="link">
                Historia
              </a>
              <a href="/site/o-neutrogenie/formula-norweska/" className="link">
                Formuła Norweska
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <section className="main">
                <div className="text full">
                  <h2>
                    Marka NEUTROGENA<sup>®</sup>&nbsp;obecna jest w ponad 70
                    krajach
                  </h2>
                  <p>
                    Nasze kosmetyki pomagają ludziom na całym świecie skutecznie
                    pielęgnować nawet najbardziej wymagającą skórę. Od początku
                    istnienia mamy ten sam, niezmienny cel. Jest nim tworzenie
                    najlepszych produktów do pielęgnacji każdego rodzaju skóry.
                    Dlatego tak dużą wagę przykładamy do składników, których
                    używamy do produkcji naszych kosmetyków.
                  </p>
                </div>
              </section>
              <section>
                <div className="bg kimj1">
                  <div className="text left">
                    <h2>Unikalne formuły</h2>
                    <p>
                      Wyjątkowość naszych produktów tkwi w łączeniu inspiracji
                      ze świata przyrody z najnowszymi badaniami naukowymi w
                      dziedzinie dermatologii. Nasi eksperci to w dużej mierze
                      autorytety naukowe z ogromnym doświadczeniem w obszarze
                      kosmetologii, dermatologii i medycyny estetycznej. Efektem
                      ich badań są specjalistyczne formuły. Unikatowa i
                      przełomowa receptura Formuły Norweskiej zrewolucjonizowała
                      rynek kosmetyczny na świecie. Jej sekretem jest idealne
                      zbilansowanie składników, które intensywnie nawilżają i
                      pielęgnują nawet najbardziej wrażliwą skórę.
                    </p>
                  </div>
                </div>
              </section>
              <section>
                <div className="bg kimj2">
                  <div className="text right">
                    <h2>Skuteczność i uznanie</h2>
                    <p>
                      Dzięki doświadczeniu i nieustającym innowacjom, marka
                      NEUTROGENA<sup>®</sup>&nbsp; jest dziś synonimem
                      skutecznych, łagodnych i odżywczych dla skóry produktów
                      pielęgnacyjnych. Cieszą się one uznaniem konsumentów i są
                      rekomendowane przez środowiska lekarskie jako produkty
                      najwyższej jakości.
                    </p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg kimj3">
                  <div className="text left">
                    <h2>Nasze produkty</h2>
                    <p>
                      Oddajemy w Twoje ręce szeroką gamę produktów marki
                      NEUTROGENA<sup>®</sup>. Znajdziesz wśród nich
                      specjalistyczne linie regenerujące i pielęgnacyjne
                      dedykowane poszczególnym częściom ciała, m.in. skórze rąk,
                      stóp oraz ust. Dzięki współpracy z dermatologami i
                      zastosowaniu unikatowej kompozycji składników aktywnych,
                      stworzyliśmy kosmetyki, które doskonale pielęgnują nawet
                      najbardziej wymagającą skórę. Nasze produkty dają Ci
                      pewność, że całe Twoje ciało będzie odpowiednio zadbane, a
                      skóra idealnie odżywiona i nawilżona.
                    </p>
                  </div>
                </div>
              </section>
              <div id="social_id_13"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/produkty/">zobacz produkty neutrogena</a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
